import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ProductHeader from "../../components/Products/Header";
import ProductDetail from "../../components/Products/Detail";
import Layout from "../../templates/layout";

import { ListOfProducts } from "../../constants";

const CurrentIndex = 1;

const Detail = () => {
  const { t } = useTranslation();
  const productName = t(`dataProducts.${ListOfProducts[CurrentIndex].data.link}.name`)
  // const productName = 'aa'
  
  return (
    <>
      <Helmet>
        <title>{`${productName} | ${t(
          "meta.title"
        )}`}</title>
        <meta content={ListOfProducts[CurrentIndex].alt} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout
        currentLink="product/flat-or-brick-coconutshell-briquettes"
        description={ListOfProducts[CurrentIndex].alt}
      >
        <>
          <ProductHeader
            title={productName}
            linkTitle={productName
              .replaceAll(" ", "-")
              .toLowerCase()}
            isProduct
            isTitleH1
          />
          <ProductDetail
            image={ListOfProducts[CurrentIndex].src}
            altImages={ListOfProducts[CurrentIndex].alt}
            productName={productName}
            productDesc={t(`dataProducts.${ListOfProducts[CurrentIndex].data.link}.desc`)}
            size={t(`dataProducts.${ListOfProducts[CurrentIndex].data.link}.size`)}
            spec={t(`dataProducts.${ListOfProducts[CurrentIndex].data.link}.spec`)}
            characteristic={t(`dataProducts.${ListOfProducts[CurrentIndex].data.link}.characteristic`)}
            packaging={t(`dataProducts.${ListOfProducts[CurrentIndex].data.link}.packaging`)}
            tags={ListOfProducts[CurrentIndex].data.tags}
          />
        </>
      </Layout>
    </>
  );
};

export default Detail;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
